'use client'
import React from 'react'
import Link from '../../common/link'
import { NextSeo } from 'next-seo'
import { DEFAULT_404_IMG_URL } from '../../../constants/media'
import cx from 'classnames'
import styles from './404.module.scss'

export default function Custom404Template({ isAppRouter = false }) {
  return (
    <div>
      {!isAppRouter ? (
        <NextSeo
          title={"Not Found - We're Sorry, but we cant find the page you requested | Drive"}
        />
      ) : null}
      <div
        className={cx(styles['drive-404__wrapper'])}
        style={{
          backgroundImage: `url('${DEFAULT_404_IMG_URL}')`
        }}
      >
        <h1 className={cx(styles['drive-404__hero-text'])}>404</h1>
        <h4 className={cx(styles['drive-404__sub-text'])}>Oh no! We've broken down!</h4>
        <p className={cx(styles['drive-404__msg'])}>
          We can’t seem to find the page you are looking for.
        </p>
        <Link href='/'>
          <a className={cx(styles['drive-404__go-back'])}>Go Back</a>
        </Link>
      </div>
    </div>
  )
}
